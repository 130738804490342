import React, { Component} from "react";
import CookieConsent from "react-cookie-consent";
import './App.css';
import { Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import {Collapse} from 'bootstrap/dist/js/bootstrap';
//images
import Logo from './images/my_logo_cropped.png'
import main_image from "./images/Kanonikes/11.jpg";
// components
import About from "./components/About.component";
import Contact from "./components/Contact.component";
import MyGallery from "./components/Gallery.component";
import Services from "./components/Services.component";
import Timetable from "./components/Timetable.component";
import Footer from "./components/Footer.component";



class App extends Component {
  constructor(props) {
    super(props);
    

    this.state = {

    };
  }
  componentDidMount () {
    const script = document.createElement("script");
    script.src = "https://my.setmore.com/webapp/js/src/others/setmore_iframe.js";
    script.async = true;
    document.body.appendChild(script);
    
    
    const navLinks = document.querySelectorAll('.nav-item')
    const menuToggle = document.getElementById('navbarNavAltMarkup')
    navLinks.forEach((l) => {
        l.addEventListener('click', () => { (new Collapse(menuToggle)).hide() })
        })
  
  }
  
  componentWillUnmount() {
    
}


  render(){

  return (
    <div className="App" >
      <CookieConsent
        location="bottom"
        buttonText="OK"
        cookieName="myAwesomeCookieName2"
        style={{ opacity: "0.8" , background: "#27272D" }}
        buttonStyle={{ "border-radius": "5px", background: "silver", color: "black", fontSize: "1.4vmin" }}
        expires={150}
      >
        <span style={{ fontSize: "1.3vmin" }}>We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it.</span>
      </CookieConsent>
      <div id="hidden"> <h1>Single Page Websites</h1> </div>
      <div>
      <nav id="NavBar"  className="nav navbar responsive-navbar-nav  navbar-expand-lg navbar-light bg-light sticky-top flex-md-row  responsive-navbar-nav navbar-inverse"  role="navigation">
      
      <div id="Contents" class='menu-container' >
      
      <div class="navbar-header">
      <button type="button" class="navbar-toggler navbar-toggler-left"  data-toggle="collapse" data-target=".navbar-collapse" aria-expanded="false" aria-controls="navbarNavAltMarkup"  >
      <span class="navbar-toggler-icon"></span>
      </button>

      </div>
      
      



    <div class="navbar-actions">
      <div class="navbar-collapse responsive-navbar-nav collapse" aria-expanded="false"  id="navbarNavAltMarkup">
      
      <ul class='nav navbar-nav navbar-nav-left ' data-split-after="5">

        <li>   
            <a class="nav-item nav-link" href="#About">ABOUT</a>
        </li>
        <li>   
            <a class="nav-item nav-link" href="#Gallery">GALLERY</a>
        </li>
        <li>   
            <a class="nav-item nav-link" href="#Timetable">TIMETABLE</a>
        </li>
        <li>   
            <a class="nav-item nav-link" href="#Services">SERVICES</a>
        </li>
        <li>   
            <a class="nav-item nav-link" href="#Contact">CONTACT</a>
        </li>
      </ul>
  </div>
  </div>
  </div>
        <div class="col"></div>

        <div id="Brand" class="navbar-header navbar-brand col" >
          <a href="/">
          <img src={Logo} id="logo" alt=""></img>
          </a>
        </div>
        


        <form class="form-inline my-2 my-lg-0 navbar-header">

        <button id="Setmore_button_iframe" class="btn btn-outline-dark btn-md col mr-auto "  href="https://my.setmore.com/bookingpage/926ffaa4-fd2a-4b90-8396-bec13975e4cb" onClick={this.floating_page} type="button">Book Now</button>
        </form>
        </nav>
        
      


        
        <img src={main_image} id="Main_Image" alt=""></img>

        <div id="AllComponents" class="">
        
        <div id='About' >

          <Route  component={About} />
        </div>


        <div id='Gallery'>

        <Route  component={MyGallery} />
        </div>

        <div id='Timetable'>

        <Route  component={Timetable} />
        </div>


        <div id='Services'>

        <Route  component={Services} />
        </div>

        <div id='Contact'>

        <Route  component={Contact} />
        </div>
        <div id='Footer'>

        <Route  component={Footer} />

      
        </div>
        </div>
    </div>
    </div>
  );
}
}
export default App;
