import React, { Component } from "react";
//import timetable_image from "../images/timetable.jpg";
import m24 from "../images/Mikres/24.jpg";
export default class Timetable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }


  render() {
    return (
      <div id="NoMarginNoPadding">
            
      <div id="timetable-margin">
        <p></p>
        <h6> <b>TIMETABLE</b></h6>
        <p></p>
      </div>  
      <div id="NoMarginNoPadding" class="row align-items-center">
      <div id="Timetable_image" class="col">
         <img  src={m24} width="100%" alt="" />
      </div>
        <div id="Timetable_Borders" class="col">
            
          <p> <h6> MONDAY </h6>CLOSED</p>
          <p></p> <p></p> <p></p>
          <p> <h6> TUESDAY </h6>10:00 - 21:00</p>
          <p></p> <p></p> <p></p>
          <p> <h6> WEDNESDAY </h6>09:00 - 20:00</p>
          <p></p> <p></p> <p></p>
          <p> <h6> THURSDAY </h6>10:00 - 21:00</p>
          <p></p> <p></p> <p></p>
          <p> <h6> FRIDAY </h6>09:00 - 21:00</p>
          <p></p> <p></p> <p></p>
          <p> <h6> SATURDAY </h6>10:00 - 16:00</p>
          <p></p> <p></p> <p></p>
          <p> <h6> SUNDAY </h6>CLOSED</p>
          <p></p> <p></p> <p></p>
         </div>
         </div>
    </div>
    );
  }
}
