import React, { Component } from "react";
import Gallery from 'react-grid-gallery';
import k1 from "../images/Kanonikes/1.jpg";
import k3 from "../images/Kanonikes/3.jpg";
import k6 from "../images/Kanonikes/6.jpg";
import k8 from "../images/Kanonikes/8.jpg";
import k9 from "../images/Kanonikes/9.jpg";
import k10 from "../images/Kanonikes/10.jpg";
import k11 from "../images/Kanonikes/11.jpg";
import k13 from "../images/Kanonikes/13.jpg";
import k14 from "../images/Kanonikes/14.jpg";
import k15 from "../images/Kanonikes/15.jpg";
import k16 from "../images/Kanonikes/16.jpg";
import k17 from "../images/Kanonikes/17.jpg";
import k18 from "../images/Kanonikes/18.jpg";
import k20 from "../images/Kanonikes/20.jpg";
import k21 from "../images/Kanonikes/21.jpg";
import k22 from "../images/Kanonikes/22.jpg";
import m1 from "../images/Mikres/1.jpg";
import m3 from "../images/Mikres/3.jpg";
import m6 from "../images/Mikres/6.jpg";
import m8 from "../images/Mikres/8.jpg";
import m9 from "../images/Mikres/9.jpg";
import m10 from "../images/Mikres/10.jpg";
import m11 from "../images/Mikres/11.jpg";
import m13 from "../images/Mikres/13.jpg";
import m14 from "../images/Mikres/14.jpg";
import m15 from "../images/Mikres/15.jpg";
import m16 from "../images/Mikres/16.jpg";
import m17 from "../images/Mikres/17.jpg";
import m18 from "../images/Mikres/18.jpg";
import m20 from "../images/Mikres/20.jpg";
import m21 from "../images/Mikres/21.jpg";
import m22 from "../images/Mikres/22.jpg";

const images = [
{src:k1,thumbnail:m1,caption:''},
//{src:k23,thumbnail:m23,caption:''},
//{src:k2,thumbnail:m2,caption:''},
{src:k3,thumbnail:m3,caption:''},
//{src:k4,thumbnail:m4,caption:''},
//{src:k5,thumbnail:m5,caption:''},
{src:k6,thumbnail:m6,caption:''},
//{src:k7,thumbnail:m7,caption:''},
{src:k8,thumbnail:m8,caption:''},
{src:k9,thumbnail:m9,caption:''},
{src:k10,thumbnail:m10,caption:''},
{src:k11,thumbnail:m11,caption:''},
//{src:k12,thumbnail:m12,caption:''},
{src:k13,thumbnail:m13,caption:''},
{src:k14,thumbnail:m14,caption:''},
{src:k15,thumbnail:m15,caption:''},
{src:k16,thumbnail:m16,caption:''},
{src:k17,thumbnail:m17,caption:''},
{src:k18,thumbnail:m18,caption:''},
//{src:k19,thumbnail:m19,caption:''},
{src:k20,thumbnail:m20,caption:''},
{src:k21,thumbnail:m21,caption:''},
{src:k22,thumbnail:m22,caption:''}
//{src:k24,thumbnail:m24,caption:''},
]



export default class MyGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  render() {
   return (

      <div id="NoMarginNoPadding">
         <div id="gallery-margin">
            <p></p>
               <h6> <b>GALLERY</b></h6>
            <p></p>     
         </div>
         <div id="Gallery_margin">
         <Gallery   images={images} enableImageSelection={false} backdropClosesModal={true} rowHeight="15vw" preloadNextImage={true} margin="0.3vw"  />
         </div>
      </div>
    );
  }
}
