import React, { Component } from "react";

export default class About extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  render() {
    return (

          <div id="NoMarginNoPadding">
            
            <div id="about-color-margin">
              <p></p>
              <h6> <b>ABOUT</b></h6>
              <p></p>
                <p id="about-par"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vel aliquam lorem. Phasellus hendrerit mi non luctus bibendum. Vivamus vel vestibulum leo. Donec egestas vulputate eros ac faucibus. Cras faucibus urna in ligula posuere, feugiat laoreet risus elementum. Donec semper, eros at commodo tempor, diam est aliquam lorem, a sagittis nibh lorem et lacus. Fusce metus ligula, hendrerit ac augue sit amet, egestas volutpat metus. Sed id augue eu est semper porta sed eu enim. Ut consequat pellentesque massa. Aliquam eget neque id nisl molestie dapibus eget eget velit. Ut bibendum, nisl eget convallis laoreet, ipsum erat auctor tortor, ut tempor tortor massa vel nunc. Morbi elementum facilisis fermentum. Cras in ligula nisi.</p>
            </div>
          </div>
    );
  }
}
