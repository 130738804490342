import React, { Component } from "react";

export default class Services extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  render() {
    return (

          <div id="NoMarginNoPadding">
            <div id="Services_margin">
            <div id="Services_header">
              <p></p>
              <h6> <b>SERVICES</b></h6>
              <p></p>
            </div>
            <div id="Services_color">
            <div id="Services_letters">
               <p>
               <div class="row">

               <div class="col" id="text_left">15 Mins Meeting</div> 
               <div class="col" id="text_right"><b>32 €</b></div> 
               </div>
               <hr/>
               <div class="row">

               <div class="col" id="text_left">30 Mins Meeting</div> 
               <div class="col" id="text_right"><b>44 €</b></div> 
               </div>
               <hr/><div class="row">

               <div class="col" id="text_left">60 Mins Meeting</div> 
               <div class="col" id="text_right"><b>132 €</b></div> 
               </div>
               <hr/><div class="row">

               <div class="col" id="text_left">Single Page Website</div> 
               <div class="col" id="text_right"><b>300 €</b></div> 
               </div>
               <hr/>
               </p>
            </div>
            </div>
            </div>
          </div>
    );
  }
}
