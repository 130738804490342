import React, { Component } from "react";
import { SocialIcon } from 'react-social-icons';

export default class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  render() {
    return (

          <div id="NoMarginNoPadding">
            <div id="Footer_margin">
            <div id="Footer_color">
            <div id="Footer_letters">
               <div class="row">

               <div class="col" id="text_left">
                  <p>Follow us:</p>
                  <div id="Social" class='row'>
                    <div id="fb" class="col">

                  <SocialIcon target="_blank" network="facebook"  url="https://www.facebook.com/alexandre.kyriakakis" />
                    </div>
                    <div class="col">

                  <SocialIcon target="_blank" network="instagram" url="https://www.instagram.com/mariamatheou_11" />
                    </div>
                    <div class="col"></div>
                    <div class="col"></div>
                    <div class="col"></div>
                    <div class="col"></div>
                    <div class="col"></div>
                  </div>

               </div> 
               <div class="col" id="text_right">
                  <p>Contact:</p>
                  <a>+30 693 0410414</a>
                  <p>Vafiochoriou 73, Poligono</p>
                  </div> 
               </div>
               <div id="Rights">
               <p>All rights reserved @single-page-websites</p>
               <p></p><p></p><p></p><p></p>
               </div>
               
               

            </div>
            </div>
            </div>
          </div>
    );
  }
}
