import React, { Component } from "react";
export default class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }


  render() {
    return (
      <div id="NoMarginNoPadding">
            
      <div id="timetable-margin">
        <p></p>
        <h6> <b>Contact</b></h6>
        <p></p>
      </div>  
      <div id="ContactFrame" class="row align-items-center">
      <div id="Maps_Iframe_margin" class="col">
      <iframe id="Maps" title="Gmaps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12576.30022402241!2d23.7513826!3d37.998709749999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a1a2a59abb7383%3A0x22ee65d26716233!2sVafiochoriou%2073%2C%20Athina%20114%2076!5e0!3m2!1sen!2sgr!4v1617208861764!5m2!1sen!2sgr"  allowfullscreen="" frameborder="0" loading="lazy"></iframe>
        
      </div>
        <div id="Contact_Borders" class="col">
            
          <a>  Vafiochoriou 73, Poligono, Athens</a>
          <p></p> <p></p> <p></p>
          <a href="tel:+30-693-0410414" > +30 693 0410414</a>
          <p></p> <p></p> <p></p>
          <a href="mailto:info@single-page-websites.com"> info@single-page-websites.com</a>
          <p></p> <p></p> <p></p>
         </div>
         </div>
    </div>
    );
  }
}
